@font-face {
  font-family: Suisse;
  src: url(./Suisse.ttf);
}

body {
  margin: 0;
  background: #fff;
  justify-content: center;
  border: none;
}

iframe {
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100vw;
  /* max-width: 1400px; */
  border: 2px white !important;
  height: 100vh;
  background: #fff;
  outline: 2px solid white;
}



.bm-overlay {
  backdrop-filter: blur(16px);
}

.bm-menu-wrap {
  width: 100% !important;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  left: 20px;
  bottom: 20px;
  background: #f1f1f5;
  padding: 8px;
  border-radius: 1000px;
}

.bm-burger-button:hover {
  background: #E8E8ED;
  transition: 0.2s;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #131313;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #222;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #484848;
  width: 2px !important; 
}

.bm-cross-button:hover .bm-cross {
  transition: 0.2s;
  background: #f1f1f5;
}

.bm-burger-bars {
  height: 10% !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #131313;
  padding: 8px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #f1f1f5;
  padding: 24px;
  font-family: Suisse;
}

a {
  color: #f1f1f5;
  padding: 8px 12px;
  text-decoration: none;
  transition: 0.2s;
  border-radius: 100px;
  width: max-content;
  display: inline-block;
}

/* Individual item */
.bm-item {
  /* display: inline-block; */
}

a:hover {
  background: #484848;
}

.meritLogo {
  width: 100%;
  max-width: 120px;
  display: flex;
  justify-items: flex-end;
  position: absolute;
  bottom: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.hoverButton {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 8px 16px;
  background: #131313;
  color: #f1f1f5;
  font-family: Suisse;
  border-radius: 1000px;
  border: none;
}

button a {
  color: #f1f1f5;
  padding: 0px !important;
  text-decoration: none;
}

button a:hover {
  background: none;
}